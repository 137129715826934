export default {
  dev: {
    admin: 'https://skordev.com/admin-dashboard/#/report',
    app: 'https://skordev.com/',
    basePath: 'https://pru-pwa.skordev.com/#/',
    img: 'https://skordev.com',
    flexiUrl: 'https://rewardzflexi.skordev.com/'
  },
  prod: {
    admin: 'https://cerrapoints.com/admin-dashboard/#/report',
    app: 'https://cerrapoints.com/',
    basePath: 'https://pru-pwa.cerrapoints.com/#/',
    img: 'https://cerrapoints.com',
    flexiUrl: 'https://pruflex.cerrapoints.com/'
  },
};
